import {createReducer, fluxStandardActionHandleEnhancer} from 're-reducer'
import {produce} from 'immer'

import {namespace, initialState} from './selectors'

const reducer = createReducer({
  namespace,
  initialState,
  handleEnhancer: fluxStandardActionHandleEnhancer,
  handles: {
    update(state, action) {
      const {
        payload: {
          token,
          user
        }
      } = action

      const nextState = produce(state, (draft) => {
        draft.token = token
        draft.authorized = !!token
        draft.user = user
        draft.pending = false

        return draft
      })

      return nextState
    }
  }
})

export default reducer
