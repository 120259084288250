import axios from 'axios'

function createInstance(config, interceptors) {
  const instance = axios.create(config)

  if (interceptors) {
    const {
      request,
      response,
    } = interceptors

    if (request) {
      instance.interceptors.request.use(...request)
    }

    if (response) {
      instance.interceptors.response.use(...response)
    }
  }

  return instance
}

class AxiosClient {
  constructor(config, interceptors) {
    this._config = config
    this._interceptors = interceptors
  }

  clone() {
    return new AxiosClient(this._config, this._interceptors)
  }

  getConfig() {
    return this._config
  }

  setConfig(config) {
    this._config = config

    if (this._instance) {
      this._instance = createInstance(config, this._interceptors)
    }

    return this
  }

  setInterceptors(interceptors) {
    this._interceptors = interceptors

    if (this._instance) {
      this._instance = createInstance(this._config, interceptors)
    }

    return this
  }

  updateConfig(config) {
    return this.setConfig({
      ...this._config,
      ...config,
    })
  }

  getInstance() {
    if (!this._instance) {
      this._instance = createInstance(this._config, this._interceptors)
    }

    return this._instance
  }

  request(options) {
    return this
      .getInstance()
      .request(options)
  }
}

export default AxiosClient
