import {AUTH_HEADER, CODEX_API_ENDPOINT} from 'services/constants'
import AxiosClient from 'services/utils/AxiosClient'
import HeaderAuth from 'services/utils/HeaderAuth'
import withAuth from 'services/utils/withAuth'

const headerAuth = new HeaderAuth(AUTH_HEADER)
const AuthClient = withAuth(headerAuth)(AxiosClient)

export const codexClient = new AuthClient({
  baseURL: CODEX_API_ENDPOINT
})

export function authorize(token) {
  codexClient.authorize(token)
}
