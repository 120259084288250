class HeaderAuth {
  constructor(header) {
    this._header = header
  }

  authorize(client, token) {
    const {
      headers,
    } = client.getConfig() || {}

    return client.updateConfig({
      headers: {
        ...headers,
        [this._header]: token,
      },
    })
  }
}

export default HeaderAuth
