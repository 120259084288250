function withAuth(auth) {
  return (Client) => {
    class AuthClient extends Client {
      authorize(...args) {
        return auth.authorize(this, ...args)
      }
    }

    return AuthClient
  }
}

export default withAuth
