import {createStore} from 'redux'

import {createError, reportError} from 'services/store/middlewares/errorHandle'
import rootReducer from './rootReducer'
import enhancer from './enhancer'

const initialState = window.__INITIAL_STATE__

const store = createStore(
  rootReducer,
  initialState,
  enhancer
)

if (process.env.NODE_ENV !== 'production') {
  window.__STORE__ = store
}

export default store
export {
  createError,
  reportError
}
