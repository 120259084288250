export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const AUTH_HEADER = process.env.REACT_APP_AUTH_HEADER
export const HUKOU_API_ENDPOINT = process.env.REACT_APP_HUKOU_API_ENDPOINT
export const CODEX_API_ENDPOINT = process.env.REACT_APP_CODEX_API_ENDPOINT
export const UPLOAD_IMAGES_ENDPOINT = `${CODEX_API_ENDPOINT}/uploads/image`

export const WEBSITE_TITLE_TEMPLATE = `%s - ${WEBSITE_NAME}`
export const BASE_PAGINATION = {
  pageSizeOptions: ['10', '20', '50', '100', '200'],
  showQuickJumper: true,
  showSizeChanger: true,
  showTotal(total) {
    return `共 ${total} 项`
  }
}
