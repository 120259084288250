import {decode} from 'jsonwebtoken'

import tokenCache from 'services/cache/token'
import {authorize as authorizeApi} from 'services/api'
import reducer from 'services/store/profile'
import {tokenSelector, authorizedSelector} from 'services/store/profile/selectors'

export function unauthorize() {
  return (dispatch) => {
    tokenCache.remove()
    dispatch(reducer.actions.update({
      token: null,
      user: null
    }))
  }
}

export function authorize(token) {
  return (dispatch, getState) => {
    const state = getState()
    const prevToken = tokenSelector(state)
    const authorized = authorizedSelector(state)

    if (!token) {
      dispatch(unauthorize())

      return
    }

    if (authorized && token === prevToken) {
      return
    }

    return Promise
      .resolve()
      .then(() => {
        const {
          id,
          username,
          roles,
          exp
        } = decode(token)

        if (exp * 1e3 < Date.now()) {
          dispatch(unauthorize())

          return
        }

        const user = {
          id,
          username,
          roles
        }

        tokenCache.set(token)
        authorizeApi(token)

        dispatch(reducer.actions.update({
          token,
          user
        }))
      })
      .catch((err) => {
        dispatch(reducer.actions.update.error(err))
      })
  }
}
